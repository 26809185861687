<template>
  <span>
    <v-card
      tile
      flat
      class="noscroll d-flex flex-column charcoal"
      v-touch:swipe.left="swipeOff"
    >
      <v-card-actions tile class="charcoal paper--text pl-2 pr-1">
        <BaseActionButton
          icon="mdi-trash-can-outline"
          plain
          :large="!isPhone"
          v-if="canEditExercise"
          v-bind="$attrs"
          label="Delete"
          color="orange darken-1"
          class="ml-n3"
          @clickedThis="showDeleteDialog = true"
        />
        <v-spacer />
        <BaseActionButton
          icon="mdi-pencil"
          color="paper"
          text
          :large="!isPhone"
          v-if="canEditExercise"
          label="Edit"
          @clickedThis="loadEdit(exercise.id)"
        />
        <v-icon color="silver" large @click="cancel" title="Close">
          mdi-close
        </v-icon>
      </v-card-actions>
      <v-card-text class="py-0 px-1">
        <ExerciseInfoPage
          v-if="loaded"
          :exercise="exercise"
          :primary="primary"
          :secondary="secondary"
          :stabilizers="stabilizers"
          :equipment="equipment"
          :isSmall="isPhone"
        />
      </v-card-text>
      <v-spacer />
      <v-card-actions class="charcoal pa-0 ">
        <v-spacer />
        <BaseActionButton
          icon="mdi-close"
          label="Close"
          color="paper"
          plain
          :large="!isPhone"
          @clickedThis="cancel"
        />
      </v-card-actions>
    </v-card>
    <v-dialog v-model="showDeleteDialog" persistent width="700">
      <v-sheet color="lightHeader" class="silver--text">
        <v-card-title class="title">
          Delete Exercise?
        </v-card-title>
      </v-sheet>
      <v-form ref="deleteExerciseForm" name="reviewExerciseForm">
        <v-card tile>
          <v-card-text>
            <v-card-subtitle class="title accent--text">
              Exercise Name
              <div class="body-1 primary--text" v-html="exercise.name"></div>
            </v-card-subtitle>
            <v-card-subtitle class="body-1 pt-5">
              Review the information below. Click the
              <span class="accent--text">Delete</span> button to remove this
              exercise. This action will make the exercise no longer available
              for selection when creatibng a workout.
            </v-card-subtitle>
          </v-card-text>
          <v-card-actions>
            <BaseActionButton
              icon="mdi-close"
              label="Cancel"
              plain
              color="info"
              @clickedThis="showDeleteDialog = false"
            />
            <v-spacer />
            <BaseActionButton
              label="Delete"
              plain
              icon="mdi-trash-can-outline"
              @clickedThis="deleteExercise()"
            />
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </span>
</template>
<script>
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'

const ExerciseInfoPage = () =>
  import('@/components/pageutil/ExerciseInfoPage.vue')

export default {
  components: { ExerciseInfoPage },
  mixins: [util],
  data: () => ({
    exercise: {},
    glossary: [],
    primary: [],
    secondary: [],
    stabilizers: [],
    equipment: [],
    switchNum: 0,
    loaded: false,
    showDeleteDialog: false
  }),
  beforeMount() {},
  props: {
    exerciseid: {
      type: String
    }
  },
  watch: {
    exerciseid: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler(val) {
        if ((val != null) & (val != '')) this.loadExercise()
      }
    }
  },
  computed: {
    canEditExercise() {
      if (!this.loggedIn) return false
      return this.xvy || this.exercise.author == this.userid
    },
    ...appConfig
  },
  methods: {
    swipeOff() {
      this.$emit('swipe_off')
    },
    loadEdit(exerciseid) {
      this.$router.push({
        path: '/exercises/edit/' + exerciseid
      })
    },
    cancel() {
      this.$emit('cancel')
    },
    deleted() {
      this.$emit('deleted')
    },
    loadGlossary() {
      {
        return axios
          .get(this.baseURL + '/util/glossary/all', {})
          .then(response => {
            if (response.status == 200) {
              this.glossary = response.data.data

              this.theforces = this.glossary.filter(function(item) {
                return item.category == 'Force'
              })
              this.mechanics = this.glossary.filter(function(item) {
                return item.category == 'Mechanics'
              })
              this.utility = this.glossary.filter(function(item) {
                return item.category == 'Utility'
              })
              this.dificultyList = this.glossary.filter(function(item) {
                return item.category == 'Dificulty'
              })
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    deleteExercise() {
      return axios
        .delete(this.baseURL + '/exercise/' + this.exercise.id, {})
        .then(response => {
          if (response.status == 200) {
            this.showDeleteDialog = false
            this.$emit('cancel')
            this.toast(
              'Exercise: ' + this.exercise.name + ' was succesfully deleted.'
            )
            this.deleted()
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadExercise() {
      {
        this.loaded = false
        this.exercise = {}
        let url = '/exercises/view/public/'
        if (this.loggedIn) url = '/exercises/view/'
        return axios
          .get(this.baseURL + url + this.exerciseid, {})
          .then(response => {
            if (response.status == 200) {
              this.exercise = response.data.data
              let muscles = JSON.parse(this.exercise.muscles)
              this.equipment = JSON.parse(this.exercise.equipment)

              this.primary = muscles
                ? muscles.filter(function(item) {
                    return item.type == 'Primary'
                  })
                : null
              this.secondary = muscles
                ? muscles.filter(function(item) {
                    return item.type == 'Secondary'
                  })
                : null
              this.stabilizers = muscles
                ? muscles.filter(function(item) {
                    return item.type == 'Stabilizers'
                  })
                : null
              this.loadGlossary()
              this.loaded = true
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    }
  }
}
</script>
